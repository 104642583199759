.fixed .QuickBox {
  height: 45vh;
  position: absolute;
  left: 5vw;
  top: 6vh;
  min-height: 35vh;
  width: 548px;
}

/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* input[type='number'] {
  -moz-appearance: textfield;
} */

.customer-links {
  overflow: hidden;
  text-align: center;
  font-weight: 700;
  font-style: normal;
  color: rgb(115, 115, 115) !important;
  text-decoration: none;
  font-size: 16px !important;
  font-family: 'GlacialIndifferenceBold';
  margin-bottom: 0px !important;
}

.customer-links:before,
.customer-links:after {
  background-color: rgb(115, 115, 115) !important;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.customer-links:before {
  right: 0.5em;
  margin-left: -50%;
  bottom: 2px;
}

.customer-links:after {
  left: 0.5em;
  margin-right: -50%;
  bottom: 2px;
}

.GetAQuote {
  background-color: white;
}

.GetAQuote .header-title {
  height: 90px;
}

.ExpressButtonsRow {
  background-color: #f8f8f8;
  padding: 10px;
  padding-top: 10px;
  margin-top: 8px;
}

.ExpressButtonsRow .button-grid {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 0px;
}

.ExpressButtonsRow .desktop-row {
  display: grid;
}

.ExpressButtonsRow .mobile-row {
  display: none;
}

.ExpressButtonsRow .margin-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.ExpressButtonsRow .margin-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.MIPPIPUploadRow .margin-6 {
  margin-top: 6px;
  margin-bottom: 6px;
}

.GetAQuote h2 {
  margin: 24px !important;
  color: #1d4c80 !important;
  font-size: 42px !important;
  font-weight: lighter !important;
  font-family: raleway;
  font-weight: 700 !important;
}

.GetAQuote button:hover {
  background: rgb(255, 128, 0) !important;
}

.ExpressButtonsRow .express-title {
  color: #1d4c80 !important;
  font-size: 22px !important;
  font-family: raleway;
  margin: 12px 0 !important;
  margin-top: 4px !important;
  margin-bottom: 0px !important;
  padding-left: 8px;
  min-width: 350px;
}

.MIPPIPUploadRow .express-title {
  color: #1d4c80 !important;
  font-size: 24px !important;
  font-family: raleway;
  margin: 12px 0 !important;
  margin-top: 8px !important;
  margin-bottom: 0px !important;
  padding-left: 8px;
}

.QuickBox .sub-header-title {
  font-weight: 400;
  font-style: normal;
  color: rgb(45, 44, 33);
  line-height: 1.6;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-decoration: none;
  font-family: GlacialIndifference;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-align: center;
  display: block;
}

.GetAQuote .sub-header-title {
  font-size: 24px;
}

.ExpressButtonsRow .sub-header-title {
  font-size: 20px;
}

.ExpressButtonsRow .express-button {
  width: 100%;
  margin: auto;
  padding: 0px 3px;
  padding-right: 12px;
  text-align: right;
}

.ExpressButtonsRow .express-button button {
  background: rgb(255, 128, 0);
  border: none;
  width: 128px;
  margin-right: auto;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  height: 42px;
  border-radius: 0.15625rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #fff !important;
}

.MIPPIPUploadRow .express-button {
  width: 132px;
  margin: auto;
}

.MIPPIPUploadRow .express-button button {
  background: rgb(255, 128, 0);
  border: none;
  width: 128px;
  margin-right: auto;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  height: 42px;
  border-radius: 0.15625rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #fff !important;
}

.ButtonsRow .express-button {
  margin: 12px 0 !important;
}

.margin-auto {
  margin: auto;
}

.width-300 {
  width: 90%;
  margin: auto;
}

.GetAQuote .width-300 {
  width: 100%;
}

.period {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0;
  margin-top: 2px;
  background-image: radial-gradient(rgb(55, 105, 152) 50%, black 100%);
  display: inline-block;
  margin: 2px 2px;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.GetAQuote :global .ant-form-inline .ant-form-item {
  margin-right: 0px;
}

.GetAQuote .form-inline :global(.ant-input::-webkit-input-placeholder) {
  font-size: 1rem !important;
}

.GetAQuote .form-inline input {
  background-color: #eee;
  width: 12vw;
  padding: 5% 7%;
  border: solid transparent;
  border-width: 1px;
  line-height: 1.5;
  margin-left: 50px;
  height: 45px;
  width: 150px !important;
  margin: auto;
  border: 1px solid rgb(55, 105, 152) !important;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1rem;
}

.GetAQuote .form-inline button,
.form-inline button:active,
.GetAQuote .form-inline button:focus,
.GetAQuote .form-inline button:hover {
  background-color: #ff8000;
  border-color: #ff8000;
  color: #fff;
}

.GetAQuote .error-message {
  color: #ff4d4f;
  margin-left: 10px;
  margin-top: 4px;
}

.GetAQuote .form-inline button,
.GetAQuote .form-inline a {
  background-color: #ff8000;
  color: #efefef;
  padding: 5% 7%;
  margin-right: auto;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.15625rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  height: 45px;
  width: 136px;
}

.GetAQuote .form-inline a {
  font-size: 14px;
}

.GetAQuote .form-inline a span {
  vertical-align: initial;
}

.GetAQuote .past-quote-container {
  padding-top: 12px;
  text-align: center;
  width: 100%;
  margin-bottom: 12px;
}

.GetAQuote .past-quote {
  color: rgb(55, 105, 152);
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 2px;
  font-size: 0.9em;
  text-decoration: underline !important;
  font-weight: 600;
  font-family: 'GlacialInDifference';
  letter-spacing: inherit;
  text-decoration: underline;
  font-size: 16px !important;
  font-weight: 300 !important;
}

.form-inline :global(input::placeholder) {
  color: #545454 !important;
  opacity: 0.9;
  /* font-size: 14em !important; */
}

.GetAQuote .start-quote {
  color: #fff !important;
}

.GetAQuote :global(.ant-input:placeholder-shown) {
  color: #545454 !important;
  opacity: 0.9;
  font-family: GlacialIndifference !important;
}

.GetAQuote .form-inline .qs-start-quote span {
  line-height: 1.5;
}

.GetAQuote .margin-bottom-18 {
  margin-bottom: 12px !important;
}

.GetAQuote .line-height-1 {
  line-height: 1 !important;
}

.GetAQuote .margin-bottom-12 {
  margin-bottom: 12px !important;
}

@media (max-width: 600px) {
  .ExpressButtonsRow .express-title {
    font-size: 18px !important;
    font-family: raleway;
    margin: 14px 0 !important;
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    padding-left: 0px;
    min-width: unset;
  }
  .ExpressButtonsRow .express-button {
    height: auto !important;
    margin: 8px 0 !important;
  }
  .ExpressButtonsRow .express-button button {
    font-size: 12px;
    width: 72px;
  }
  .ExpressButtonsRow .header-title {
    height: auto !important;
    width: auto;
  }
  .QuickBox span.sub-header-title {
    font-size: 15px;
  }
  .ExpressButtonsRow .express-button button {
    margin-top: 4px;
    line-height: 1;
    height: 32px;
    width: auto;
  }

  .ExpressButtonsRow .button-grid {
    grid-gap: 5px;
  }

  .ExpressButtonsRow .button-grid .period {
    width: 6px;
    height: 6px;
  }

  .ExpressButtonsRow .margin-8 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .MIPPIPUploadRow .express-title {
    font-size: 18px !important;
    font-family: raleway;
    margin: 14px 0 !important;
    margin-top: 2px !important;
    margin-bottom: 0 !important;
    padding-left: 0px;
  }
  .MIPPIPUploadRow .express-button {
    height: auto !important;
    margin: 8px 0 !important;
  }
  .MIPPIPUploadRow .express-button button {
    font-size: 12px;
    width: 72px;
  }
  .MIPPIPUploadRow .header-title {
    height: auto !important;
    width: auto;
    text-align: center;
  }
  .MIPPIPUploadRow .margin-8 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .QuickBox span .sub-header-title {
    font-size: 15px;
  }
  .MIPPIPUploadRow .express-button button {
    margin-top: 4px;
    line-height: 1;
    height: 32px;
  }
  .MIPPIPUploadRow .button-grid {
    /* display: block; */
    margin-bottom: 4px;
  }
  .MIPPIPUploadRow .express-button button {
    font-size: 12px;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .GetAQuote .form-inline button,
  .GetAQuote .form-inline a {
    height: 45px;
    width: 136px;
  }
  .GetAQuote .form-inline input {
    height: 45px;
    width: 150px !important;
  }
  .GetAQuote .past-quote-container a {
    font-size: 16px !important;
  }
  .ExpressButtonsRow .express-title {
    min-width: unset;
  }
}

@media (max-width: 1200px) {
  .ExpressButtonsRow .express-title {
    min-width: unset;
  }
  .ExpressButtonsRow .header-title {
    height: auto!important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .GetAQuote .form-inline button,
  .GetAQuote .form-inline a {
    height: 40px;
    width: 130px;
    font-size: 14px;
  }
  .GetAQuote .form-inline input {
    height: 40px;
    width: 130px !important;
  }
  .GetAQuote .past-quote-container a {
    font-size: 14px !important;
  }
  .GetAQuote h2 {
    font-size: 40px !important;
  }
  .GetAQuote .past-quote-container {
    padding-left: 10px !important;
  }
}

@media (max-width: 767px) {
  .GetAQuote .form-inline button,
  .GetAQuote .form-inline a {
    height: 35px;
    width: 120px;
    font-size: 14px;
  }
  .GetAQuote .form-inline input {
    height: 35px;
    width: 120px;
  }
  .GetAQuote .past-quote-container a {
    font-size: 14px !important;
  }
  .GetAQuote h2 {
    font-size: 28px !important;
  }
  .header-title {
    height: 70px !important;
  }
  /* .GetAQuote {
    min-height: 30vh;
  } */
  .customer-links {
    font-size: 12px !important;
  }
}

@media (max-width: 460px) {
  .ExpressButtonsRow .desktop-row {
    display: none;
  }
  
  .ExpressButtonsRow .mobile-row {
    display: block;
  }
  .ExpressButtonsRow .express-button, 
  .ExpressButtonsRow .express-button button {
    width: 100%;
    font-size: 14px;
    padding: 0 2px 0 3px;
  }
  .ExpressButtonsRow .mobile-button {
    display: block;
  }
  .GetAQuote .header-title {
    height: 72px;
  }
  .fixed .QuickBox {
    left: 1.9vh;
    top: 2vh;
  }
  .GetAQuote h2 {
    margin: 12px 24px !important;
    color: #1d4c80 !important;
    font-size: 38px !important;
    font-weight: lighter !important;
    font-family: raleway;
    font-weight: 700 !important;
  }
}

@media (max-width: 430px) {
  .header-title {
    height: 56px !important;
  }
  .GetAQuote {
    min-height: 26vh;
  }
  .GetAQuote h2 {
    font-size: 28px !important;
  }
  .QuickBox {
    width: 100%;
    left: 4vw !important;
  }
}
@media (max-width: 390px) {
  .QuickBox {
    left: 2vh !important;
  }
}

@media (max-width: 376px) {
  .header-title {
    height: 56px !important;
  }

  .QuickBox {
    left: 2vh !important;
  }
  /* .QuickBox {
    width: 100%;
    left: 4vw !important;
  } */
}
