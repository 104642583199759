.my-account-screen {
  margin: auto;
  max-width: 1140px;
  min-height: 55vh;
}

.my-account {
  background-color: #fff;
}

.my-account .hero {
  background: linear-gradient(to right, rgb(127, 135, 160), rgb(54, 68, 99));
  height: 359px;
  width: 100%;
  overflow: hidden;
}

.hero-container {
  display: flex !important;
  height: 100%;
}

.my-account-screen .text-underline {
  text-decoration: underline !important;
  margin: 0 !important;
}

.my-account-screen .login-class {
  margin: auto;
  min-width: 360px;
  max-width: 360px;
}

.my-account-screen .create-account-container {
  font-weight: 400;
  font-style: normal;
  font-size: 21px!important;
}

.my-account-screen .create-account-container .account-link {
  font-weight: bold;
}

.my-account .express-button span {
  word-wrap: break-word;
  width: 150px;
  white-space: normal;
  vertical-align: middle;
}

.hero-container img {
  max-width: inherit;
  max-height: inherit;
  height: inherit;
  width: inherit;
  object-fit: cover;
}

.my-account .hero .banner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.my-account .hero .hero-content {
  -ms-flex-preferred-size: 70%;
  flex-basis: 70%;
  padding: 50px 0 10px 0px;
  -ms-flex-item-align: flex-end;
  align-self: flex-end;
}

.my-account .row-container {
  height: 100%;
}

.my-account .forgot-text {
  color: rgba(0, 0, 0, 0.85) !important;
  cursor: pointer;
  margin-right: 3px;
}

.my-account-screen .title {
  text-transform: inherit;
}

.my-account .bottom-text {
  min-width: 438px;
}

.my-account .my-account-sub-title,
.my-account .my-account-sub-title-qs {
  width: 100%;
  font-family: raleway;
  font-weight: 400 !important;
  font-size: 28px !important;
  margin-bottom: 0 !important;
  margin-left: 0px !important;
}
.my-account .my-account-sub-title-qs {
  margin-left: 8px !important;
}

.my-account .my-account-sub-title-qs {
  margin-bottom: 0px !important;
}

.my-account-screen .ButtonsRow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 50px 50px;
  grid-gap: 10px;
  background-color: inherit;
  padding-top: 0;
  margin-top: 8px;
}

.my-account-screen :global .hide-login,
.my-account-screen :global .hide-quick-service,
.my-account-screen :global .hide-qs-service,
.my-account-screen :global .hide-mobile-modal {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s, overflow 0s;
  border: none;
}

.my-account-screen :global .show-mobile-modal {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.my-account-screen :global .ant-card-head-title {
  width: 100%;
  font-family: raleway;
  font-weight: 400 !important;
  font-size: 30px !important;
  margin-bottom: 0 !important;
  margin-left: 0px !important;
  text-align: center;
  color: rgb(29, 76, 128) !important;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  font-family: GlacialInDifferenceBold;
  margin-top: 4px;
}

.my-account-screen .margin-0 {
  margin-left: 0px !important;
}
.my-account-screen :global .hide-mobile-select-options {
  display: none;
  transition: opacity 1s ease-out;
  opacity: 0;
}

.my-account-screen :global .show-mobile-select-options {
  display: block;
  opacity: 1;
}

.my-account-screen :global .bg-color-blue {
  background: #d1e4fc;
}

.my-account-screen .forgot-links-height-0 {
  height: auto;
  margin-top: 12px;
  margin-bottom: 12px;
}

.my-account-screen :global .ant-card-head {
  border-bottom: 0px;
  padding-right: 0;
  padding-left: 0;
}

.my-account-screen :global .ant-card-body {
  padding-top: 0px;
}

.my-account-screen .signin-close {
  color: #1d4c80;
  font-size: 18px;
}

.my-account :global .show-login .create-account-text {
  height: auto;
}

.my-account .create-account-text {
  height: 28px;
}

.my-account-screen :global .show-login,
.my-account-screen :global .show-qs-service {
  max-height: 36em;
  overflow: hidden;
  transition: max-height 0.5s, overflow 0.5s 0.5s;
  margin-bottom: 9px;
  margin-top: 0px;
}

.my-account-screen :global .show-quick-service {
  max-height: 31em;
  overflow: hidden;
  transition: max-height 0.5s, overflow 0.5s 0.5s;
  margin-bottom: 12px;
  padding-top: 0px;
}

.my-account-screen :global .show-quick-service {
  max-height: 31em;
  overflow: hidden;
  transition: max-height 0.5s, overflow 0.5s 0.5s;
  margin-bottom: 12px;
  margin-top: 0px;
}

.my-account-screen .card-title {
  margin: 0;
}

.my-account-screen .ButtonCell {
  width: 150px !important;
  display: block;
  height: 150px;
  margin-bottom: 12px;
  align-items: center;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
}

.my-account-screen .ButtonCell .row-center {
  justify-content: center;
}

.my-account-screen .ButtonCell .button-text {
  text-align: center;
  align-items: center;
}

.my-account .height-12 {
  height: 12px;
  display: block;
}

.my-accounts .margin-auto {
  margin: auto;
}

.my-account-screen .login-form-button,
.my-account-screen .login-form-button:hover,
.my-account-screen .login-form-button:active,
.my-account-screen .login-form-button:focus {
  margin-right: 0px;
  background: #ff8000;
  border: none;
  color: #fff;
  font-family: 'GlacialIndifference';
  text-transform: uppercase;
}

.my-account-screen .forgot-links {
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 12px;
  height: 30px;
  font-size: 16px;
}

.my-account .input-text input {
  font-size: 1.2em;
  font-family: 'GlacialIndifference';
  background: #fff;
}

.my-account .input-text {
  border: 1px solid rgb(55, 105, 152) !important;
}

.my-account-screen .qs-forgot-links {
  height: 10px;
}

.my-account-screen .forgot-links a,
.my-account-screen .create-account-text a {
  color: rgb(55, 105, 152);
  margin-top: 6px;
}

.my-account-screen .create-account-text {
  justify-content: start;
  margin-bottom: 6px;
  font-size: 16px;
}

.my-account-screen :global .ant-card-body span {
  font-size: 16px;
}
.my-account .margin-left-26 {
  margin-left: 26px;
}
.my-account .margin-left-24 {
  margin-left: 24px;
}
.my-account .margin-left-42 {
  margin-left: 42px;
}
.my-account .margin-left-18 {
  margin-left: 18px;
}

.my-account .margin-bottom-12 {
  margin-bottom: 12px;
}
.my-account .margin-left-5 {
  margin-left: 5px;
}
.my-account :global .ant-form-item-control-input-content {
  width: 260px !important;
}

.my-account-screen .disabled-disclaimer {
  float: right;
}
.my-account-screen .pay-bill button {
  margin: auto;
}

.my-account-screen .btn-control {
  text-align: center;
  margin-bottom: 0;
}

.my-account-screen .btn-control button {
  width: 150px;
  border-radius: 12px;
}

.my-account-screen .margin-auto {
  margin: auto;
}
.my-account-screen .GetAQuote .header-title {
  height: 72px;
}

.my-account-screen .QuickBox {
  margin: auto;
  background-color: white;
}

.my-account-screen .GetAQuote {
  background-color: white;
}
.my-account-screen .GetAQuote h2 {
  font-size: 32px !important;
}

.my-account-screen .GetAQuote .past-quote {
  font-size: 26px !important;
  text-decoration: none !important;
}

.my-account .ButtonCell span {
  text-decoration: none !important;
}

.my-account .get_id_card img,
.my-account .make_payment img,
.my-account .manage_policy img {
  width: 90px;
  height: 84px;
  margin-bottom: 32px;
}

.my-account-screen .mi-drivers img,
.my-account-screen .nj-drivers img,
.my-account-screen .pa-drivers img {
  margin-bottom: 12px;
  border: 1px solid #1d4c80 !important;
}

.my-account-screen .mi-drivers img:hover,
.my-account-screen .nj-drivers img:hover,
.my-account-screen .pa-drivers img:hover {
  border: 1px solid #1d4c80 !important;
}

.my-account-screen .manage-policy,
.my-account-screen .get-id-card,
.my-account-screen .make-payment,
.my-account-screen .express-changes {
  display: block;
  height: auto;
  width: 240px;
  margin: auto;
  margin-bottom: 24px;
  margin-left: 26px !important;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  border-radius: 12px;
  border-color: #1d4c80;
}

.my-account-screen .manage-policy:hover,
.my-account-screen .get-id-card:hover,
.my-account-screen .make-payment:hover,
.my-account-screen .express-changes:hover {
  color: #1d4c80;
  border-color: #1d4c80;
}

.my-account-screen .manage-policy img {
  content: url('https://assets.cure.com/img/main-site/manage-policy.png');
  width: 62px;
  height: 62px;
  display: inline-block;
  margin-right: 12px;
}

.my-account-screen .get-id-card img {
  content: url('https://assets.cure.com/img/main-site/get-id-card.png');
  width: 62px;
  height: 62px;
  display: inline-block;
  margin-right: 12px;
}

.my-account-screen .make-payment img {
  content: url('https://assets.cure.com/img/main-site/make-payment.png');
  width: 62px;
  height: 62px;
  display: inline-block;
  margin-right: 12px;
}

.my-account-screen .express-changes img {
  content: url(https://assets.cure.com/img/main-site/stop-watch.png);
  width: 62px;
  height: 62px;
  display: inline-block;
  margin-right: 12px;
}

.my-account-screen :global .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 6px
}

.my-account-screen .account-button {
  display: grid;
  justify-content: center;
}

.my-account-screen .mi-drivers img {
  content: url('https://assets.cure.com/img/main-site/michigan.png');
  width: 180px;
  height: 180px;
  display: block;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  margin-left: 8px;
  border-radius: 12px;
}

.my-account-screen .height-1 {
  height: 1px;
  margin-bottom: 10px !important;
}
.my-account-screen .text-center {
  text-align: center !important;
  font-weight: 400;
  font-style: normal;
  color: rgb(29, 76, 128) !important;
  text-decoration: none;
  width: 100%;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  font-size: 30px !important;
  font-family: GlacialInDifferenceBold;
}

.my-account-screen .text-left {
  text-align: left !important;
}

.my-account-screen .nj-drivers img {
  content: url('https://assets.cure.com/img/main-site/new-jersey.png');
  width: 180px;
  height: 180px;
  display: block;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  margin-left: 26px;
  border-radius: 12px;
}

.my-account .subtext {
  font-weight: 400;
  font-style: normal;
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  width: 100%;
  color: rgb(0, 0, 0);
  line-height: 1.58em;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  font-size: 21px !important;
  font-family: GlacialInDifference;
}

.my-account-screen .pa-drivers img {
  content: url('https://assets.cure.com/img/main-site/pennsylvania.png');
  width: 180px;
  height: 180px;
  display: block;
  box-shadow: 0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  margin-left: 26px;
  border-radius: 12px;
}

.my-account-screen .sub-title {
  text-align: left;
  margin-bottom: 12px;
  font-size: 16px;
  display:block;
}

.my-account-screen .create-account {
  justify-content: center;
}

.my-account-screen .create-account a {
  color: #294c7c;
  text-decoration: underline;
}

.my-account-screen .link {
  margin-left: 12px;
}

.my-account-screen .quick-service {
  justify-content: center;
  margin-top: 60px;
}

.my-account-screen .disclaimer {
  justify-content: center;
}

.my-account .button-text {
  margin-top: 8px;
}

@media (min-width: 1219px) and (max-width: 1200px) {
  .my-account-screen span .sub-title {
    min-width: 100px;
  }
}

@media (min-width: 1025px) and (max-width: 1220px) {
  .my-account .hero .hero-content {
    padding: 50px 0 10px 24px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .my-account-screen span.sub-title {
    margin-left: 20px !important;
    min-width: 100px;
  }
  .my-account .hero .hero-content {
    padding: 50px 0 10px 24px;
  }
  .my-account-drivers-row {
    width: 100% !important;
    justify-content: center !important;
  }
  .my-account-screen .mi-drivers img {
    margin-left: 0px !important;
  }
  .my-account-screen .margin-left-below-login {
    margin-left: 50px !important;
  }
}

@media (max-width: 768px) {
  .my-account-screen span .sub-title {
    width: 100%;
    min-width: 325px;
  }
  .my-account-screen .GetAQuote {
    min-height: auto;
  }
  .my-account-screen .GetAQuote h2 {
    font-size: 26px !important;
  }
  .my-account-screen .mi-drivers img,
  .my-account-screen .nj-drivers img,
  .my-account-screen .pa-drivers img {
    width: 120px;
    height: 120px;
  }
  .my-account-screen .GetAQuote .header-title {
    height: 54px !important;
  }
  .my-account-screen .GetAQuote .past-quote {
    font-size: 24px !important;
  }
  .my-account .hero-content {
    flex-basis: 40% !important;
  }

  .my-account-screen .my-account-drivers-row {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

@media (max-width: 550px) {
  .my-account-screen .mi-drivers img,
  .my-account-screen .nj-drivers img,
  .my-account-screen .pa-drivers img {
    width: 110px;
    height: 100px;
  }
  .my-account .hero-content {
    flex-basis: 40% !important;
  }
  .my-account-screen .my-account-drivers-row {
    display: flex;
    justify-content: center;
  }
  .my-account-screen .margin-left-26 {
    margin-left: 0px !important;
  }
}

@media (max-width: 550px) {
  .my-account .hero .hero-content {
    flex-basis: 0% !important;
  }
  .my-account .hero {
    height: 420px;
  }
}

@media (max-width: 430px) {
  .my-account-screen .mi-drivers img,
  .my-account-screen .nj-drivers img,
  .my-account-screen .pa-drivers img {
    width: 65%;
    height: 90%;
    margin-left: 18px;
    display: grid;
    margin: auto;
  }
  .my-account-screen .mobile-margin-right-0 {
    margin-right: 0px;
  }
  .my-account-screen :global .show-quick-service {
    min-height: 448px;
  }
  .my-account-screen :global .show-login {
    min-height: 490px;
  }
  .my-account .ButtonCell {
    width: 100% !important;
    height: 100%;
  }
  .my-account .margin-left-26 {
    margin-left: 10px;
  }
  .my-account .get_id_card img,
  .my-account .make_payment img,
  .my-account .manage_policy img {
    width: 90px;
    height: 60px;
    margin-bottom: 32px;
  }
  .my-account .my-account-sub-title,
  .my-account .my-account-sub-title-qs {
    margin-left: 20px !important;
  }
  .my-account .my-account-sub-title,
  .my-account .my-account-sub-title-qs {
    margin-left: 0px !important;
    margin-top: 0px!important;
  }
  .my-account span .sub-title {
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .my-account span .subtext {
    text-align: center;
    width: 94%;
  }
  .my-account .manage-policy,
  .my-account .get-id-card,
  .my-account .make-payment {
    margin-left: 0px !important;
  }
  .my-account .create-account-text {
    height: auto;
  }
  .my-account-screen .sub-title {
    margin-bottom: 0px;
  }
  .my-account-screen :global .show-login .ant-card-head-title {
    padding-bottom: 10px;
  }
  .my-account-screen :global .show-login .ant-card-extra {
    padding-bottom: 12px;
  }
  .my-account-screen :global .show-login .forgot-links {
    margin-bottom: 20px;
  }
  .my-account-screen .qs-forgot-links {
    height: 0px;
  }
  .my-account :global .show-quick-service .ant-card-body {
    padding-bottom: 14px;
  }
  .my-account-screen :global .show-quick-service {
    margin-bottom: 6px;
    min-height: 36em;
  }
  .my-account-screen .express-changes {
    margin-left: 0px !important;
  }
  .my-account-screen :global .bg-color-blue {
    background: #d1e4fc;
    padding-top: 20px;
  }
  .my-account-screen .create-account-container .account-link {
    font-weight: bolder;
    font-family: 'GlacialIndifferenceBold';
  }
}

@media (max-width: 376px) {
  .my-account-screen .mi-drivers img,
  .my-account-screen .pa-drivers img,
  .my-account-screen .nj-drivers img {
    width: 65%;
    height: 90%;
    margin-left: 18px;
    display: grid;
    margin: auto;
  }
  .my-account-screen .QuickBox {
    width: 100%;
    left: 0vw !important;
    margin: 0;
  }
  .my-account-screen .ButtonsRow {
    grid-gap: 0px;
  }
  .my-account .hero {
    background: linear-gradient(to right, rgb(127, 135, 160), rgb(54, 68, 99));
    height: 420px;
  }
  .my-account-screen .ButtonCell {
    height: 120px;
    margin-right: 12px;
  }
  .my-account-screen .ButtonCell {
    width: 96px !important;
  }
  .my-account .my-account-sub-title,
  .my-account .my-account-sub-title-qs {
    margin-left: 0px !important;
    margin-top: 0px!important;
  }
  .my-account span.sub-title {
    margin: auto;
    width: 94%;
    text-align: center;
  }
  .my-account .manage-policy,
  .my-account .get-id-card,
  .my-account .make-payment {
    margin-left: 0px !important;
  }
  .my-account .header-hero {
    height: 148px;
  }
  .my-account-screen :global .bg-color-blue {
    background: #d1e4fc;
    padding-top: 20px;
  }
}

@media (max-width: 360px){
  .my-account-screen .login-class {
    margin: auto;
    min-width: 330px;
    max-width: 330px;
  }
  .my-account-screen :global .bg-color-blue {
    background: #d1e4fc;
    padding-top: 20px;
  }
}