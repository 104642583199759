.fixed-support-box {
  z-index: 999;
}

.SupportBox {
  width: 516px;
  background: #fff;
  border-radius: 60px;
  opacity: 0.9;
  padding: 20px;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  margin-right: 60px;
}

.SupportBox button {
  margin-left: 27px;
  margin-top: 12px;
  background-color: rgb(255, 128, 0);
  height: 48px;
  width: 150px;
}

.SupportBox button,
.SupportBox button:hover,
.SupportBox button:active,
.SupportBox button:focus {
  background-color: #ff8000;
  border-color: #ff8000;
  color: #fff;
}

.SupportBox h2 {
  margin-bottom: 8px !important;
  text-align: left;
  margin-top: 12px !important;
  font-family: raleway;
  font-weight: 400 !important;
}

.SupportBox .sub-text {
  text-align: inherit;
  margin-left: 27px;
  margin-right: 27px;
  font-size: 20px;
  line-height: 1.25;
  text-transform: none;
  color: #333333 !important;
  font-family: 'GlacialIndifference';
}

.SupportBox .sub-text span {
  text-align: inherit;
  font-size: 20px;
  line-height: 1.25;
  text-transform: none;
  color: #333333 !important;
}

.SupportBox button {
  margin-left: 27px;
  margin-top: 12px;
  background-color: rgb(255, 128, 0);
  height: 48px;
  width: 150px;
}

.SupportBox button,
.SupportBox button:hover,
.SupportBox button:active,
.SupportBox button:focus {
  background-color: #ff8000;
  border-color: #ff8000;
  color: #fff;
}

@media (max-width: 700px) {
  .SupportBox {
    width: 490px;
  }
}

@media (max-width: 580px) {
  .SupportBox {
    width: 92%;
    right: 6px;
    left: 14px;
    bottom: 66px;
    top: 0px;
    margin-right: 0px;
    padding: 12px;
  }
  .SupportBox h2 {
    font-size: 26px !important;
  }
  .fixed-support-box .button-container {
    margin: auto;
  }
  .SupportBox .sub-text {
    line-height: 22px;
  }

  .SupportBox .sub-text span {
    font-size: 16px !important;
    margin-right: 10px;
  }

  .SupportBox button {
    width: 130px;
    height: 38px;
    margin-left: 0;
    margin-top: 0px;
  }
  .SupportBox button span {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .SupportBox .sub-text span {
    text-align: left;
    font-size: 16px !important;
  }
}

@media (max-width: 430px) {
  .SupportBox .cursive {
    height: 38px !important;
    font-size: 25px !important;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 390px) and (max-width: 581px) {
  .SupportBox {
    width: 92%;
    right: 6px;
    height: auto;
    left: 14px;
    bottom: 66px;
    top: 0px;
  }
}
