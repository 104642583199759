.customer-links {
  overflow: hidden;
  text-align: center;
  font-weight: 700;
  font-style: normal;
  color: rgb(115, 115, 115) !important;
  text-decoration: none;
  font-size: 16px !important;
  font-family: 'GlacialIndifferenceBold';
  margin-bottom: 0px !important;
}

.customer-links:before,
.customer-links:after {
  background-color: rgb(115, 115, 115) !important;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}

.customer-links:before {
  right: 0.5em;
  margin-left: -50%;
  bottom: 2px;
}

.customer-links:after {
  left: 0.5em;
  margin-right: -50%;
  bottom: 2px;
}

.ButtonsRow {
  background-color: #f8f8f8;
  padding: 10px;
  padding-top: 10px;
  margin-top: 8px;
}

.ButtonsRow .button-grid {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 24px;
}

@media (min-width: 1200px) {
  .customer-links {
    font-size: 16px !important;
  }
}

@media (max-width: 767px) {
  .customer-links {
    font-size: 12px !important;
  }
}
