.file_claim img {
  content: url('https://assets.cure.com/img/main-site/file-claims.png');
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  display: inherit;
}
.get_id_card img {
  content: url('https://assets.cure.com/img/main-site/get-id-card.png');
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  display: inherit;
}
.create_account img {
  content: url('https://assets.cure.com/img/main-site/create-account.png');
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  display: inherit;
}
.make_payment img {
  content: url('https://assets.cure.com/img/main-site/make-payment.png');
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  display: inherit;
}
.manage_policy img {
  content: url('https://assets.cure.com/img/main-site/manage-policy.png');
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
  display: inherit;
}

.file_claim,
.get_id_card,
.make_payment,
.manage_policy,
.create_account {
  background-color: transparent !important;
  border-color: transparent !important;
  padding: 0 !important;
  height: 48px !important;
}

.ButtonCell {
  display: inline-block;
  min-height: 90px;
  height: auto;
  margin-right: 0.25vw;
  font-weight: 700;
  font-size: 1vw;
  color: rgb(10, 103, 169);
}

.ButtonCell span {
  font-weight: 700;
  font-style: normal;
  color: rgb(29, 76, 128);
  text-decoration: underline;
  font-size: 14px;
  line-height: 1.3em;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  text-align: center;
  font-family: 'GlacialIndifferenceBold' !important;
}

.ButtonCell button:hover,
.ButtonCell button:active,
.ButtonCell button:focus {
  background: transparent !important;
}

.button-text {
  line-height: 0px;
  margin-top: 6px;
  font-size: 13px;
}

@media (min-width: 1200px) {
  .file_claim img {
    content: url('https://assets.cure.com/img/main-site/file-claims.png');
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    display: inherit;
  }
  .get_id_card img {
    content: url('https://assets.cure.com/img/main-site/get-id-card.png');
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    display: inherit;
  }
  .create_account img {
    content: url('https://assets.cure.com/img/main-site/create-account.png');
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    display: inherit;
  }
  .make_payment img {
    content: url('https://assets.cure.com/img/main-site/make-payment.png');
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    display: inherit;
  }
  .manage_policy img {
    content: url('https://assets.cure.com/img/main-site/manage-policy.png');
    width: 48px;
    height: 48px;
    margin-bottom: 20px;
    display: inherit;
  }

  .file_claim,
  .get_id_card,
  .make_payment,
  .manage_policy,
  .create_account {
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0 !important;
  }

  .ButtonCell {
    display: inline-block;
    width: 88px !important;
    min-height: 90px;
    height: auto;
    margin-right: 0.25vw;
    font-weight: 700;
    font-size: 1vw;
    color: rgb(10, 103, 169);
    width: 19.27%;
  }

  .ButtonCell span {
    font-weight: 700;
    font-style: normal;
    color: rgb(29, 76, 128);
    text-decoration: underline;
    font-size: 14px;
    line-height: 1.3em;
    letter-spacing: 0em;
    --para-spacing: 0;
    text-transform: none;
    --head-indent: 0;
    --numeric-list-marker: none;
    list-style-type: none;
    text-align: center;
    font-family: 'GlacialIndifferenceBold' !important;
  }

  .ButtonCell button:hover,
  .ButtonCell button:active,
  .ButtonCell button:focus {
    background: transparent !important;
  }

  .button-text {
    line-height: 0px;
    margin-top: 6px;
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .button-text {
    margin-top: 4px;
  }
}

@media (max-width: 767px) {
  .file_claim img,
  .get_id_card img,
  .make_payment img,
  .manage_policy img,
  .create_account img {
    width: 32px;
    height: 32px;
  }
  .button-text {
    margin-top: 4px;
    font-size: 11px;
  }
  .ButtonCell {
    height: auto !important;
    min-height: 68px;
  }
  .ButtonCell span {
    font-size: 11px !important;
  }
  .file_claim,
  .get_id_card,
  .make_payment,
  .manage_policy,
  .create_account {
    height: 32px !important;
  }
}

@media (max-width: 480px) {
  .ButtonCell {
    margin-right: 10px;
    margin-left: 10px;
  }
}